import { EventEmitter } from "events";

export default class Modal extends EventEmitter {

    constructor(containingElem, isMultipleValue) {
        super();
        this.containingElem = containingElem;
        this.isMultipleValue = isMultipleValue;
    }

    render() {
        let modalDiv = document.createElement('div');
        modalDiv.classList.add('ezajil-modal');
        modalDiv.setAttribute('id', 'ezajil-modal');
        let modalContentDiv = document.createElement('div');
        modalContentDiv.classList.add('ezajil-modal-content');
        let closeBtn = document.createElement('span');
        closeBtn.classList.add('close');
        closeBtn.innerHTML = '&times;';
        closeBtn.onclick = () => this.close();
        modalContentDiv.appendChild(closeBtn);

        let chatroomForm = document.createElement('form');
        chatroomForm.setAttribute('id', 'chatroom-form');
        if (this.isMultipleValue) {
            // TODO multi-select with autocomplete
            let nameInputLabel = document.createElement('label');
            nameInputLabel.setAttribute('for', 'name');
            nameInputLabel.innerHTML = 'Chatroom name:';
            let nameInput = document.createElement('input');
            nameInput.setAttribute('id', 'name');
            nameInput.setAttribute('type', 'text');
            nameInput.classList.add('ml-2');
            nameInput.classList.add('mb-3');

            let participantsInputLabel = document.createElement('label');
            participantsInputLabel.setAttribute('for', 'participants');
            participantsInputLabel.innerHTML = 'Choose participants:';
            let participantsSelect = document.createElement('select');
            participantsSelect.setAttribute('id', 'participants');
            participantsSelect.setAttribute('multiple', '');
            participantsSelect.classList.add('ml-2');
            participantsSelect.classList.add('p-4');
            for (let username of ['ezajil1', 'ezajil2', 'ezajil3', 'ezajil4', 'ezajil5', 'ezajil6', 'ezajil7', 'ezajil8', 'ezajil9', 'ezajil10', 'ezajil11']) {
                let participantsOption = document.createElement('option');
                participantsOption.setAttribute('value', username);
                participantsOption.innerHTML = username;
                participantsSelect.appendChild(participantsOption);
            }


            let submitInput = document.createElement('input');
            submitInput.setAttribute('type', 'submit');
            submitInput.setAttribute('value', 'Submit');
            chatroomForm.appendChild(nameInputLabel);
            chatroomForm.appendChild(nameInput);
            chatroomForm.appendChild(participantsInputLabel);
            chatroomForm.appendChild(participantsSelect);
            chatroomForm.appendChild(document.createElement('br'));
            chatroomForm.appendChild(document.createElement('br'));
            chatroomForm.appendChild(document.createElement('br'));
            chatroomForm.appendChild(submitInput);
            chatroomForm.addEventListener('submit', (event) => {
                const name = document.getElementById('name').value;
                const participants = this.getSelectValues(document.getElementById('participants'));
                if (!name || !participants || !participants.length) {
                    alert('Missing mandatory inputs');
                } else {
                    this.close();
                    this.emit('create-group-chatroom-end', event, name, participants);
                }
            });
        } else {
            let participantInputLabel = document.createElement('label');
            participantInputLabel.setAttribute('for', 'participant');
            participantInputLabel.innerHTML = 'Choose a participant:';
            let participantSelect = document.createElement('select');
            participantSelect.setAttribute('id', 'participant');
            participantSelect.classList.add('ml-2');
            for (let username of ['ezajil1', 'ezajil2', 'ezajil3', 'ezajil4', 'ezajil5', 'ezajil6', 'ezajil7', 'ezajil8', 'ezajil9', 'ezajil10', 'ezajil11']) {
                let participantOption = document.createElement('option');
                participantOption.setAttribute('value', username);
                participantOption.innerHTML = username;
                participantSelect.appendChild(participantOption);
            }
            let submitInput = document.createElement('input');
            submitInput.setAttribute('type', 'submit');
            submitInput.setAttribute('value', 'Submit');
            submitInput.classList.add('ml-2');
            chatroomForm.appendChild(participantInputLabel);
            chatroomForm.appendChild(participantSelect);
            chatroomForm.appendChild(submitInput);
            chatroomForm.addEventListener('submit', (event) => {
                const participant = document.getElementById('participant').value;
                this.close();
                this.emit('create-single-chatroom-end', event, participant);
            });
        }
        modalContentDiv.appendChild(chatroomForm);
        modalDiv.appendChild(modalContentDiv);
        this.containingElem.appendChild(modalDiv);
    }

    close() {
        let modal = document.getElementById('ezajil-modal');
        if (!!modal) {
            this.containingElem.removeChild(modal);
        }
    }

    getSelectValues(select) {
        var result = [];
        var options = select && select.options;
        var opt;

        for (var i = 0, iLen = options.length; i < iLen; i++) {
            opt = options[i];

            if (opt.selected) {
                result.push(opt.value || opt.text);
            }
        }
        return result;
    }
}

