import { EventEmitter } from "events";
import Pending from '../assets/pending.svg';
import Tick from '../assets/tick.jpg';
import DoubleTick from '../assets/double-tick.jpg';
import DoubleTickBlue from '../assets/double-tick-blue.jpg';

const CHAT_BOX_WIDTH = 380;
const USER_LIST_WIDTH = 300;
const MAX_CHATBOX = Math.floor((screen.width - USER_LIST_WIDTH) / CHAT_BOX_WIDTH);

export default class Chatbox extends EventEmitter {

    constructor(elem, chatroomId, currentUser, title, nbChatBox) {
        super();
        this.elem = elem;
        this.currentUser = currentUser;
        this.chatroomId = chatroomId;
        this.title = title;
        this.messages = [];
        this.nb = nbChatBox;
        this.keyDownTimeout;
    }

    render() {
        this.chatWindow = document.createElement('div');
        this.chatWindow.setAttribute('id', this.chatroomId);
        this.chatWindow.classList.add('chatbubble', 'opened');
        if (this.nb >= 0) {
            this.chatWindow.classList.add('translateX-' + this.nb % MAX_CHATBOX);
        }

        this.chatHeader = document.createElement('div');
        this.chatHeader.classList.add('unexpanded');
        let chatHeaderDiv = document.createElement('div');
        chatHeaderDiv.setAttribute('id', 'header-title');
        let chatHeaderTitleSpan = document.createElement('span');
        chatHeaderTitleSpan.innerHTML = this.title;

        let chatHeaderHandlerBtns = document.createElement('div');
        chatHeaderHandlerBtns.classList.add('toggle-chatbox');
        let chatHeaderToggleBtn = document.createElement('span');
        // chatHeaderToggleBtn.classList.add('toggle-chatbox');
        chatHeaderToggleBtn.innerHTML = '&#8211;';
        chatHeaderToggleBtn.onclick = () => this.toggleChatWindow();

        let chatHeaderCloseBtn = document.createElement('span');
        // chatHeaderCloseBtn.classList.add('close-chatbox');
        chatHeaderCloseBtn.classList.add('mr-1');
        chatHeaderCloseBtn.innerHTML = '&times;';
        chatHeaderCloseBtn.onclick = () => this.closeChatWindow();
        chatHeaderHandlerBtns.appendChild(chatHeaderCloseBtn);
        chatHeaderHandlerBtns.appendChild(chatHeaderToggleBtn);

        this.chatHeader.appendChild(chatHeaderHandlerBtns);
        this.chatHeader.appendChild(chatHeaderTitleSpan);
        // this.chatHeader.appendChild(chatHeaderToggleBtn);
        // this.chatHeader.appendChild(chatHeaderCloseBtn);

        this.chatBody = document.createElement('div');
        this.chatBody.classList.add('chat-window', 'expanded');

        this.chatLoader = document.createElement('div');
        this.chatLoader.classList.add('loader-wrapper');
        this.chatLoader.innerHTML = '<div class="loader"><span>{</span><span>}</span></div>';

        this.chatMessagesDiv = document.createElement('div');
        this.chatMessagesDiv.classList.add('chat-history');
        this.chatMessagesUl = document.createElement('ul');
        this.chatMessagesUl.setAttribute('id', this.nb);
        this.chatMessagesUl.classList.add('messages', 'clearfix');
        this.chatMessagesDiv.appendChild(this.chatMessagesUl);

        this.chatInput = document.createElement('div');
        this.chatInput.classList.add('chat-input');
        this.chatInput.innerHTML =
            `<div class="flexContainer">
              <textarea class="chat-message-input" rows="1" placeholder="Enter Message"></textarea>
              <div class="input-icon">
                  <label for="upload-file-${this.nb}" class="upload-file-icon clickable">
                      <img src="https://img.icons8.com/ios-glyphs/25/a9a9a9/attach.png"/>
                  </label>
                  <input id="upload-file-${this.nb}" class="upload-file-${this.nb}" type="file" />
              </div>
          </div>`;

        this.chatInputIcon = document.createElement('div');

        // this.chatInput.getElementsByClassName('send-btn')[0].onclick = () => this.onSendCallback();
        let first = true;
        let chatInputTextArea = this.chatInput.getElementsByClassName('chat-message-input')[0];
        chatInputTextArea.onfocus = () => {
            if (first) {
                this.computeBaseScrollHeight(chatInputTextArea);
                first = false;
            }
        };
        chatInputTextArea.oninput = (event) => {
            chatInputTextArea.rows = 1;
            if (event.inputType === 'insertLineBreak') {
                chatInputTextArea.value = '';
            }
            let rows = Math.ceil((chatInputTextArea.scrollHeight - chatInputTextArea.baseScrollHeight) / 16);
            chatInputTextArea.rows = Math.min(rows + 1, 3);
            // Resize chat history paddings 36px
            this.chatMessagesDiv.style.height = (this.chatBody.offsetHeight - chatInputTextArea.offsetHeight - 36) + 'px';
            // console.log('Chat window height : ', this.chatBody.offsetHeight,
            //     'chatInputTextArea height : ', chatInputTextArea.offsetHeight,
            //     'Result : ', this.chatMessages.style.height);
        };

        let chatInputUpload = this.chatInput.getElementsByClassName(`upload-file-${this.nb}`)[0];
        chatInputUpload.oninput = () => {
            let file = chatInputUpload.files[0];
            if (!!file) {
                this.uploadFile(file);
            }
        };
        this.chatInput.onkeydown = (event) => {
            this._debounce(5000, true);
            event = event || window.event;
            if ((event.which === 13 || event.keyCode === 13) && !event.shiftKey) {
                this.sendMessage();
            }
        };

        this.chatBody.append(this.chatLoader);
        this.chatBody.append(this.chatMessagesDiv);
        this.chatBody.append(this.chatInput);

        this.chatWindow.append(this.chatHeader);
        this.chatWindow.append(this.chatBody);
        this.elem.appendChild(this.chatWindow);

        // Error floating div
        this.errorCard = document.createElement('div');
        this.errorCard.classList.add('overlay-card', 'clickable');
        this.errorCard.onclick = () => this.removeErrorCard();
        this.chatMessagesDiv.append(this.errorCard);

        document.querySelector('.chat-history').addEventListener('mouseenter', function (e) {
            e.target.style.overflow = 'auto';
        }, false);

        document.querySelector('.chat-history').addEventListener('mouseleave', function (e) {
            e.target.style.overflow = 'hidden';
        }, false);
    }

    _debounce(duration, immediate) {
        if (!this.keyDownTimeout) {
            this.keyDownTimeout = setTimeout(() => {
                this.keyDownTimeout = null;
                if (!immediate) {
                    this.emitUserTypingEvent();
                }
            }, duration);
        
            if (immediate) {
                this.emitUserTypingEvent();
            }
        }
        
    }

    renderUserTyping(userTypingEvent) {
        let existingUserTypingLi = document.getElementById(`user-typing-${this.chatroomId}`);
        if (!existingUserTypingLi) {
            let userTypingLi = document.createElement('li');
            userTypingLi.setAttribute('id', `user-typing-${this.chatroomId}`)
            userTypingLi.classList.add('clearfix', 'other');
            let messageSenderDiv = document.createElement('div');
            messageSenderDiv.classList.add('msg-sender');
            messageSenderDiv.innerText = userTypingEvent.userId;
            let userTypingDiv = document.createElement('div');
            userTypingDiv.classList.add('user-typing-loading');
            userTypingLi.appendChild(messageSenderDiv);
            userTypingLi.appendChild(userTypingDiv);

            this.chatMessagesUl.append(userTypingLi);
            setTimeout(() => {
                this.chatMessagesDiv.scrollTop = this.chatMessagesDiv.scrollHeight;
            }, 0);

            setTimeout(() => this.removeUserTyping(), 6000);
        }
    }

    removeUserTyping() {
        let existingUserTypingLi = document.getElementById(`user-typing-${this.chatroomId}`);
        if (!!existingUserTypingLi) {
            existingUserTypingLi.remove();
        }
    }

    translateToRight() {
        this.chatWindow.classList.remove('translateX-' + this.nb % MAX_CHATBOX);
        this.nb--;
        this.chatWindow.classList.add('translateX-' + this.nb % MAX_CHATBOX);
    }

    emitUserTypingEvent() {
        this.emit('user-typing-event');
    }

    sendMessage() {
        let chatInputTextArea = this.chatInput.getElementsByClassName('chat-message-input')[0];
        let textMessage = chatInputTextArea.value;
        if (!!textMessage && textMessage.length > 0) {
            this.emit('message-submitted', textMessage);
            chatInputTextArea.value = '';
            clearTimeout(this.keyDownTimeout);
            this.keyDownTimeout = null;
        }
    }

    removeLoader() {
        this.chatLoader.style.display = 'none';
        this.chatMessagesDiv.style.display = 'block';
        this.chatInput.style.display = 'block';
    }

    displayLoader() {
        this.chatLoader.style.display = 'block';
        this.chatMessagesDiv.style.display = 'none';
        this.chatInput.style.display = 'none';
    }

    removeErrorCard() {
        this.errorCard.style.display = 'none';
    }

    displayErrorCard(errorMessage) {
        this.errorCard.innerHTML = `<span class="error-msg">${errorMessage}</span>`;
        this.errorCard.style.bottom = this.chatInput.offsetHeight + 7;
        this.errorCard.style.display = 'block';
        setTimeout(() => {
            this.removeErrorCard();
        }, 5000);
    }

    closeChatWindow() {
        this.elem.removeChild(this.chatWindow);
        this.emit('chatbox-closed', this.nb);
    }

    toggleChatWindow() {
        this.chatWindow.classList.toggle('opened');
    }

    // Should be idempotent
    newChatMessage(message, scrollDelay, isFile) {
        // let existingMessage = this.messages.find(existingMessage => existingMessage.messageId === message.messageId);
        // if (!existingMessage) {
        let messageClass;
        if (this.currentUser.userId !== message.author) {
            messageClass = 'other';
            this.removeUserTyping();
        } else {
            messageClass = 'mine';
        }
        this.messages.push(message);
        let newMessageLi = document.createElement('li');
        newMessageLi.setAttribute('id', message.messageId);
        newMessageLi.setAttribute('timestamp', message.sendingDate);
        newMessageLi.classList.add('clearfix', messageClass);
        let messageSenderDiv = document.createElement('div');
        messageSenderDiv.classList.add('msg-sender');
        messageSenderDiv.innerText = message.screenName;
        newMessageLi.appendChild(messageSenderDiv);

        let messageContentDiv;
        if (!!message.content) {
            messageContentDiv = document.createElement('div');
            messageContentDiv.classList.add('msg-content');
            let messageContentTextDiv = document.createElement('div');
            messageContentTextDiv.innerText = message.content;
            messageContentDiv.appendChild(messageContentTextDiv);
        } else if (!!message.mediaUrls) {
            messageContentDiv = document.createElement('div');
            let messageAttachmentImg = document.createElement('img');
            messageAttachmentImg.classList.add('msg-attachment');
            messageAttachmentImg.classList.add('clickable');
            messageAttachmentImg.src = message.mediaUrls['280x280'];
            messageContentDiv.appendChild(messageAttachmentImg);
        }
        
        let messageFooter = document.createElement('div');
        messageFooter.classList.add('message-status');
        messageFooter.appendChild(this.createMessageSendingTimeSpan(message));
        if (message.author === this.currentUser.userId) {
            messageFooter.appendChild(this.createMessageStatusImg(message));
        }

        messageContentDiv.appendChild(messageFooter);
        newMessageLi.appendChild(messageContentDiv);

        if (isFile) {
            this.removePlaceholder();
        }
        let existingMessageLis = this.chatMessagesUl.childNodes;
        if (existingMessageLis.length > 0) {
            let idx = existingMessageLis.length - 1;
            while (idx >= 0) {
                const existingMessageLi = existingMessageLis[idx];
                if (parseInt(existingMessageLi.getAttribute('timestamp')) <= message.sendingDate) {
                    if (idx === existingMessageLis.length - 1) {
                        this.chatMessagesUl.append(newMessageLi);
                    } else {
                        this.chatMessagesUl.insertBefore(newMessageLi, existingMessageLi.nextSibling);
                    }
                    break;
                }
                idx--;
            }
        } else {
            this.chatMessagesUl.append(newMessageLi);
        }

        setTimeout(() => {
            this.chatMessagesDiv.scrollTop = this.chatMessagesDiv.scrollHeight;
            // scrollTo(this.chatMessages, this.chatMessages.scrollHeight, 0);
        }, scrollDelay);
        // }
    }

    createMessageSendingTimeSpan(message) {
        let messageTimeSpan = document.createElement('span');
        messageTimeSpan.classList.add('message-time');
        var now = new Date(); // replace with sdk date
        messageTimeSpan.innerText = ("0" + now.getHours()).slice(-2)   + ":" + ("0" + now.getMinutes()).slice(-2);
        return messageTimeSpan;
    }

    createMessageStatusImg(message) {
        let messageStatusImg = document.createElement('img');
        messageStatusImg.setAttribute('id', `message-status-${message.messageId}`);
        messageStatusImg.classList.add('message-status-image');
        switch (message.status) {
            case 'READ':
                messageStatusImg.src = DoubleTickBlue;
                break;
            case 'DELIVERED':
                messageStatusImg.src = DoubleTick;
                break;
            case 'SENT':
                messageStatusImg.src = Tick;
                break;
            default:
                messageStatusImg.src = Pending;
                break;
        }
        return messageStatusImg;
    }

    displayMessageAsNotSent(chatMessage) {
        let messageLi = document.getElementById(chatMessage.messageId);
        if (messageLi) {
            messageLi.innerHTML += '<div class="msg-error">not sent</div>';
        }
    }

    computeBaseScrollHeight(chatInputTextArea) {
        var savedValue = chatInputTextArea.value;
        chatInputTextArea.value = '';
        chatInputTextArea.baseScrollHeight = chatInputTextArea.scrollHeight;
        chatInputTextArea.value = savedValue;
    }

    uploadFile(file) {
        this.emit('file-upload', file);
    }

    displayPlaceholder() {
        let loadingPlaceholder = document.createElement('li');
        loadingPlaceholder.setAttribute('id', 'upload-placeholder');
        loadingPlaceholder.classList.add('clearfix', 'mine');
        loadingPlaceholder.innerHTML =
            `<div class="msg-sender">${this.currentUser.screenName}</div>
          <div class="msg-placeholder">
              <div id="floatingCirclesG">
                  <div class="f_circleG" id="frotateG_01"></div>
                  <div class="f_circleG" id="frotateG_02"></div>
                  <div class="f_circleG" id="frotateG_03"></div>
                  <div class="f_circleG" id="frotateG_04"></div>
                  <div class="f_circleG" id="frotateG_05"></div>
                  <div class="f_circleG" id="frotateG_06"></div>
                  <div class="f_circleG" id="frotateG_07"></div>
                  <div class="f_circleG" id="frotateG_08"></div>
              </div>
          </div>`;
          this.chatMessagesUl.append(loadingPlaceholder);
        this.chatMessagesDiv.scrollTop = this.chatMessagesDiv.scrollHeight;
    }

    removePlaceholder() {
        let loadingPlaceholder = document.getElementById('upload-placeholder');
        if (loadingPlaceholder) {
            loadingPlaceholder.remove();
        }
        this.chatMessagesDiv.scrollTop = this.chatMessagesDiv.scrollHeight;
    }

    readURL(file) {
        var reader = new FileReader();
        reader.onload = function (e) {
            $('#blah').attr('src', e.target.result);
        }
        reader.readAsDataURL(file);
    }

    displayMessageAsSent(messageId) {
        let messageSentIdx = this.messages.findIndex(message => message.messageId === messageId);
        this.messages[messageSentIdx].status = 'SENT';
        let messageStatusImg = document.getElementById(`message-status-${messageId}`);
        if (!!messageStatusImg) {
            messageStatusImg.src = Tick;
        }
    }

    displayMessagesAsDelivered(latestMessageDelivered) {
        this.messages
            .filter(message => message.author === this.currentUser.userId && message.status === 'SENT'
                                && message.sendingDate <= latestMessageDelivered)
            .forEach(message => {
                message.status = 'DELIVERED';
                let messageStatusImg = document.getElementById(`message-status-${message.messageId}`);
                if (!!messageStatusImg) {
                    messageStatusImg.src = DoubleTick;
                }
            });
    }

    displayMessagesAsRead(latestMessageRead) {
        this.messages.filter(message => message.author === this.currentUser.userId
            && ['SENT', 'DELIVERED'].includes(message.status) && message.sendingDate <= latestMessageRead)
            .forEach(message => {
                message.status = 'READ';
                let messageStatusImg = document.getElementById(`message-status-${message.messageId}`);
                if (!!messageStatusImg) {
                    messageStatusImg.src = DoubleTickBlue;
                }
            });
    }

    destroy() {
        let chatWindow = document.getElementById(this.chatroomId);
        if (chatWindow) {
            this.elem.removeChild(chatWindow);
        }
    }
}
