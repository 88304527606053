// import `.scss` files
import '../scss/styles.scss';

import SideBar from './sidebar';
import Chatbox from './chatbox';

import Session from './session';
import User from './user';
import Modal from './modal';

document.addEventListener('DOMContentLoaded', function () {
    const form = document.getElementById('username-form');
    form.addEventListener('submit', (event) => {
        const username = document.getElementById('username').value;

        if (!!username && username.length > 0) {
            var elem = document.getElementById('app');
            // hide modal
            const modalElt = document.getElementById('modal');
            modalElt.classList.remove("show-modal");

            // create instance of a plugin
            var appCredentials = {
                organizationId: '053f4104-6713-4362-ba02-dc8a694c8221',
                projectId: 'e06aed1d-84bc-48b0-b6c0-6513aabf621b',
                secretKey: 'SQS3JU2OWH2VU1RPS1K0W07KU7872FUH'
            };

            var currentUser = new User(appCredentials.organizationId, appCredentials.projectId, username, username, null, 'oussama@gmail.com', null, true);
            var session = new Session(currentUser, appCredentials);
            let sideBar = new SideBar(elem, username);
            sideBar.render();
            let openChatboxes = [];

            session.on('connected', () => {
                console.log('connection established');
                sideBar.onlineMode();
                session.getSingleChatroomsOfUser((singleChatrooms, error) => {
                    if (error != null) {
                        console.error('failed to load single chatrooms of user: ', error);
                    } else {
                        singleChatrooms.forEach(singleChatroom => addSingleChatroom(singleChatroom, currentUser, session, sideBar));
                    }
                });

                session.getGroupChatroomsOfUser((groupChatrooms, error) => {
                    if (error != null) {
                        console.error('failed to load group chatrooms of user: ', error);
                    } else {
                        groupChatrooms.forEach(groupChatroom => addGroupChatroom(groupChatroom, sideBar));
                    }
                });
            });

            session.on('disconnected', (code, reason) => {
                console.log(`connection closed with code '${code}' and reason '${reason}'`);
                sideBar.offlineMode();
                openChatboxes.forEach(openChatbox => openChatbox.destroy());
                openChatboxes = [];
            });
            session.on('error', (error) => {
                console.error(`${error.message}`);
                sideBar.offlineMode();
            });
            session.on('chat-message', (message) => {
                const chatbox = openChatboxes.find((chatbox) => chatbox.chatroomId === message.chatroomId);
                if (!!chatbox) {
                    // Display message in assossiated chatbox
                    session.markMessageAsRead(message.chatroomId, message.sendingDate);
                    chatbox.newChatMessage(message, 0);
                } else {
                    // Add Notification with message in navbar
                    // getChatroom to display in sidebar
                    session.markMessageAsDelivered(message);
                    session.getChatroom(message.chatroomId, (chatroom, error) => {
                        if (error != null) {
                            console.error('failed to load chatroom: ', message.chatroomId);
                        } else {
                            if (chatroom.single) {
                                addSingleChatroom(chatroom, currentUser, session, sideBar);
                            } else {
                                addGroupChatroom(chatroom, sideBar);
                            }
                        }
                    });
                }
            });
            session.on('error-message', (code, reason, chatMessage) => {
                console.log(`received error message with code '${code}' and reason '${reason}'`);
                const chatbox = openChatboxes.find((chatbox) => chatbox.chatroomId === chatMessage.chatroomId);
                if (!!chatbox) {
                    // Display error in assossiated chatbox
                    chatbox.displayErrorCard(reason);
                    chatbox.displayMessageAsNotSent(chatMessage);
                } else {
                    // Add Notification with error message in navbar
                }
            });
            session.on('online-user', (onlineUser) => {
                // Add user to sidebar
                sideBar.changeSingleChatroomStatus(onlineUser, true);
            });
            session.on('offline-user', (offlineUser) => {
                // sideBar.removeOnlineUser(offlineUser);
                sideBar.changeSingleChatroomStatus(offlineUser, false);
            });

            session.on('message-sent', (messageSent) => {
                const chatbox = openChatboxes.find((chatbox) => chatbox.chatroomId === messageSent.chatroomId);
                if (!!chatbox) {
                    chatbox.displayMessageAsSent(messageSent.messageId);
                }
                else {
                    // Ignore
                }
            });

            session.on('user-typing', (userTyping) => {
                const chatbox = openChatboxes.find((chatbox) => chatbox.chatroomId === userTyping.chatroomId);
                if (!!chatbox) {
                    chatbox.renderUserTyping(userTyping);
                }
                else {
                    // Ignore
                }
            });

            session.on('messages-delivered', (messagesDelivered) => {
                const chatbox = openChatboxes.find((chatbox) => chatbox.chatroomId === messagesDelivered.chatroomId);
                if (!!chatbox) {
                    chatbox.displayMessagesAsDelivered(messagesDelivered.latestMessageDelivered);
                }
                else {
                    // Ignore
                }
            });

            session.on('messages-read', (messagesRead) => {
                const chatbox = openChatboxes.find((chatbox) => chatbox.chatroomId === messagesRead.chatroomId);
                if (!!chatbox) {
                    chatbox.displayMessagesAsRead(messagesRead.latestMessageRead);
                }
                else {
                    // Ignore
                }
            });

            sideBar.on('create-single-chatroom-init', (event) => {
                event.preventDefault();
                let singleChatroomModal = new Modal(elem, false);
                singleChatroomModal.render();
                singleChatroomModal.on('create-single-chatroom-end', (event, participant) => {
                    event.preventDefault();
                    session.createSingleChatroom(participant, null, (singleChatroom, error) => {
                        if (error != null) {
                            console.error(`Failed to create chatroom with user '${participant}'`, error);
                            // TODO: display error toast
                        } else {
                            addSingleChatroom(singleChatroom, currentUser, session, sideBar);
                            openChatroom(elem, session, singleChatroom, currentUser, openChatboxes);
                        }
                    });
                });
            });

            sideBar.on('create-group-chatroom-init', (event) => {
                event.preventDefault();
                let groupChatroomModal = new Modal(elem, true);
                groupChatroomModal.render();
                groupChatroomModal.on('create-group-chatroom-end', (event, name, participants) => {
                    event.preventDefault();
                    session.createGroupChatroom(name, participants, null, (groupChatroom, error) => {
                        if (error != null) {
                            console.error(`Failed to create chatroom with user '${participants}'`, error);
                            // TODO: display error toast
                        } else {
                            addGroupChatroom(groupChatroom, sideBar);
                            openChatroom(elem, session, groupChatroom, currentUser, openChatboxes);
                        }
                    });
                });
            });

            sideBar.on('chatroom-click', (chatroom) => {
                openChatroom(elem, session, chatroom, currentUser, openChatboxes);
            });

            sideBar.on('online-user-click', (onlineUser) => {
                session.createChatroom([onlineUser.userId], 'todo', (chatroom, err) => {
                    if (err != null) {
                        console.error(`Failed to create chatroom with user '${onlineUser.userId}'`);
                        // TODO: display error toast
                    } else {
                        openChatroom(elem, session, chatroom, currentUser, openChatboxes);
                    }
                });
            });
        }
        event.preventDefault();
    });

    function openChatroom(elem, session, chatroom, currentUser, openChatboxes) {
        let chatboxAlreadyExists = openChatboxes.some((openChatbox) => openChatbox.chatroomId === chatroom.chatroomId);
        if (!chatboxAlreadyExists) {
            let chatbox = new Chatbox(elem, chatroom.chatroomId, currentUser, chatroom.name, openChatboxes.length);
            openChatboxes.push(chatbox);

            // Display chatbox
            chatbox.render();

            // Get latest messages & display them
            chatroom.getMessages(null, 10, (messages, error) => {
                if (error != null) {
                    chatbox.displayErrorCard(error);
                } else {
                    chatbox.removeLoader();
                    if (chatroom.single && !!messages.results && messages.results.length > 0) {
                        session.markMessageAsRead(chatroom.chatroomId, messages.results[messages.results.length - 1].sendingDate);
                    }
                    messages.results.forEach((message) => chatbox.newChatMessage(message, 0));
                }
            });

            // On new message sent
            chatbox.on('user-typing-event', () => {
                console.log('Debounced keydown event');
                session.fireUserTyping(chatroom.chatroomId, currentUser.userId);
            });

            // On new message sent
            chatbox.on('message-submitted', (message) => {
                let chatMessage = chatroom.sendChatMessage(message);
                chatbox.newChatMessage(chatMessage, 0);
            });

            // On file upload
            chatbox.on('file-upload', (file) => {
                chatbox.displayPlaceholder();
                chatroom.uploadFile(file, (message, err) => {
                    chatbox.removePlaceholder();
                    if (err != null) {
                        chatbox.displayErrorCard(err);
                    } else {
                        chatbox.newChatMessage(message, 1000, true);
                    }
                });
            });

            // On chatbox closed
            chatbox.on('chatbox-closed', (nbChatbox) => {
                let idxToRemove = openChatboxes.findIndex((chatbox) => chatbox.nb === nbChatbox);
                openChatboxes.splice(idxToRemove, 1);
                openChatboxes.filter((openChatbox) => openChatbox.nb > nbChatbox)
                    .forEach((chatboxToTranslate) => chatboxToTranslate.translateToRight());
            });
        } else {
            console.log('Chatbox already open');
        }
    }

    function addSingleChatroom(singleChatroom, currentUser, session, sideBar) {
        const otherUserId = _resolveOtherUserInSingleChatroom(currentUser, singleChatroom);
        session.subscribeToUsersPresence([otherUserId],
            (users, error) => {
                if (error != null) {
                    console.error(`failed to load user ${otherUserId}: ${error}`);
                } else {
                    singleChatroom.name = otherUserId;
                    sideBar.addSingleChatroom(singleChatroom, users[0]);
                }
            });
    }
    
    function _resolveOtherUserInSingleChatroom(currentUser, singleChatroom) {
        if (singleChatroom.participantIds.length === 1) {
            return singleChatroom.participantIds[0];
        }
        return currentUser.userId === singleChatroom.participantIds[0] ? 
            singleChatroom.participantIds[1] : singleChatroom.participantIds[0];
    }

    function addGroupChatroom(groupChatroom, sideBar) {
        sideBar.addGroupChatroom(groupChatroom);
    }
});
