import { EventEmitter } from "events";
import User from "./user";
import DefaultAvatar from '../assets/default-avatar.png';
import DefaultMultiAvatar from '../assets/default-multi-avatar.png';

export default class SideBar extends EventEmitter {

    constructor(containingElem, username) {
        super();
        this.containingElem = containingElem;
        this.username = username;
        this.singleChatrooms = [];
        this.groupChatrooms = [];
    }

    render() {
        let sidebarDiv = document.createElement('div');
        sidebarDiv.classList.add('ezajil-sidebar');

        // CURRENT USER
        let currentUserDiv = document.createElement('div');
        currentUserDiv.classList.add('current-user');
        currentUserDiv.setAttribute('id', this.username)
        currentUserDiv.innerHTML = '<span>' + this.username + '</span>';
        sidebarDiv.appendChild(currentUserDiv);

        // SINGLE CHATROOMS
        let singleChatroomsDiv = document.createElement('div');
        singleChatroomsDiv.classList.add('chatroom-list');
        let singleChatroomsTitleDiv = document.createElement('div');
        singleChatroomsTitleDiv.classList.add('section-title');
        let singleChatroomsTitleSpan = document.createElement('span');
        singleChatroomsTitleSpan.innerHTML = 'single chatrooms';
        let singleChatroomsTitleBtn = document.createElement('span');
        singleChatroomsTitleBtn.classList.add('new-chatroom-btn');
        singleChatroomsTitleBtn.classList.add('pl-5');
        singleChatroomsTitleBtn.innerHTML = 'Add +';
        singleChatroomsTitleBtn.onclick = (event) => this.emit('create-single-chatroom-init', event);

        singleChatroomsTitleDiv.appendChild(singleChatroomsTitleSpan);
        singleChatroomsTitleDiv.appendChild(singleChatroomsTitleBtn);

        this.singleChatroomsUl = document.createElement('ul');
        this.singleChatroomsUl.innerHTML = '';
        let singleLiElements = this.singleChatrooms.map(singleChatroom => this.addSingleChatroom(singleChatroom));
        for (let li of singleLiElements) {
            this.singleChatroomsUl.appendChild(li);
        }
        // Add single chatrooms listing to sidebar div
        singleChatroomsDiv.appendChild(singleChatroomsTitleDiv);
        singleChatroomsDiv.appendChild(this.singleChatroomsUl);
        sidebarDiv.appendChild(singleChatroomsDiv);

        // GROUP CHATROOMS
        let groupChatroomsDiv = document.createElement('div');
        groupChatroomsDiv.classList.add('chatroom-list');
        let groupChatroomsTitleDiv = document.createElement('div');
        groupChatroomsTitleDiv.classList.add('section-title');
        let groupChatroomsTitleSpan = document.createElement('span');
        groupChatroomsTitleSpan.innerHTML = 'group chatrooms';
        let groupChatroomsTitleBtn = document.createElement('span');
        groupChatroomsTitleBtn.classList.add('new-chatroom-btn');
        groupChatroomsTitleBtn.classList.add('pl-5');
        groupChatroomsTitleBtn.innerHTML = 'Add +';
        groupChatroomsTitleBtn.onclick = (event) => this.emit('create-group-chatroom-init', event);

        groupChatroomsTitleDiv.appendChild(groupChatroomsTitleSpan);
        groupChatroomsTitleDiv.appendChild(groupChatroomsTitleBtn);

        this.groupChatroomsUl = document.createElement('ul');
        this.groupChatroomsUl.innerHTML = '';
        let groupLiElements = this.groupChatrooms.map(groupChatroom => this.addGroupChatroom(groupChatroom));
        for (let li of groupLiElements) {
            this.groupChatroomsUl.appendChild(li);
        }
        // Add group chatrooms listing to sidebar div
        groupChatroomsDiv.appendChild(groupChatroomsTitleDiv);
        groupChatroomsDiv.appendChild(this.groupChatroomsUl);
        sidebarDiv.appendChild(groupChatroomsDiv);

        this.containingElem.appendChild(sidebarDiv);
    }

    // SHOULD BE IDEMPOTENT
    addSingleChatroom(chatroom, participant) {
        let alreadyExists = this.singleChatrooms.some(existingChatroom => existingChatroom.chatroomId === chatroom.chatroomId);
        if (!alreadyExists) {
            this.singleChatrooms.push(chatroom);
            let li = document.createElement('li');
            let div = document.createElement('div');
            div.classList.add('contact-card');

            let avatarDiv = document.createElement('div');
            avatarDiv.classList.add('avatar');
            let avatarImg = document.createElement('img');
            avatarImg.classList.add('avatar-image');
            avatarImg.src = !!participant.avatar ? participant.avatar : DefaultAvatar;
            let avatarSpan = document.createElement('span');
            avatarSpan.setAttribute('id', `status-${participant.userId}`);
            avatarSpan.classList.add('avatar-status');
            avatarSpan.classList.add(participant.online ? 'online' : 'offline');
            avatarDiv.appendChild(avatarImg);
            avatarDiv.appendChild(avatarSpan);

            let chatroomNameSpan = document.createElement('span');
            chatroomNameSpan.classList.add('ml-5');
            chatroomNameSpan.innerText = chatroom.name;

            div.appendChild(avatarDiv);
            div.appendChild(chatroomNameSpan);
            li.appendChild(div);
            this.singleChatroomsUl.appendChild(li);
            li.onclick = () => this.emit('chatroom-click', chatroom);
        }
    }

    changeSingleChatroomStatus(participant, isOnline) {
        let avatarSpan = document.getElementById(`status-${participant.userId}`);
        if (!!avatarSpan) {
            avatarSpan.classList.remove('online');
            avatarSpan.classList.remove('offline');
            avatarSpan.classList.add(isOnline ? 'online' : 'offline');
        }
    }

    // SHOULD BE IDEMPOTENT
    removeSingleChatroom(chatroom) {
        let lis = this.singleChatroomsUl.getElementsByTagName('li');
        this.singleChatrooms = this.singleChatrooms.filter(sc => sc.chatroomId !== chatroom.chatroomId);
        for (let i = 0; i < lis.length; i++) {
            let div = lis[i].getElementsByTagName('div')[0];
            let txtValue = div.textContent || div.innerText;
            if (txtValue.toUpperCase() === chatroom.name.toUpperCase()) {
                this.singleChatroomsUl.removeChild(lis[i]);
            }
        }
    }

    // SHOULD BE IDEMPOTENT
    addGroupChatroom(chatroom) {
        let alreadyExists = this.groupChatrooms.some(existingChatroom => existingChatroom.chatroomId === chatroom.chatroomId);
        if (!alreadyExists) {
            this.groupChatrooms.push(chatroom);
            let li = document.createElement('li');
            let div = document.createElement('div');
            div.classList.add('contact-card');

            let avatarDiv = document.createElement('div');
            avatarDiv.classList.add('avatar');
            let avatarImg = document.createElement('img');
            avatarImg.classList.add('avatar-image');
            avatarImg.src = !!chatroom.avatar ? chatroom.avatar : DefaultMultiAvatar;
            let chatroomNameSpan = document.createElement('span');
            avatarDiv.appendChild(avatarImg);

            chatroomNameSpan.classList.add('ml-5');
            chatroomNameSpan.innerText = chatroom.name;
            div.appendChild(avatarDiv);
            div.appendChild(chatroomNameSpan);
            li.appendChild(div);
            this.groupChatroomsUl.appendChild(li);
            li.onclick = () => this.emit('chatroom-click', chatroom);
        }
    }

    onlineMode() {
        this._setStatus('online');
    }

    offlineMode() {
        this._setStatus('offline');
        this.singleChatrooms = [];
        this.groupChatrooms = [];
        this.singleChatroomsUl.innerHTML = '';
        this.groupChatroomsUl.innerHTML = '';
    }

    _setStatus(status) {
        let currentUserDiv = document.getElementById(this.username);
        let userStatusSpan = document.getElementById('user-status');
        if (userStatusSpan) {
            currentUserDiv.removeChild(userStatusSpan);
        } else {
            userStatusSpan = document.createElement('span');
            userStatusSpan.setAttribute('id', 'user-status');
        }
        userStatusSpan.classList = [];
        userStatusSpan.classList.add(`${status}-user`);
        userStatusSpan.innerText = status;
        currentUserDiv.appendChild(userStatusSpan);
    }
}
